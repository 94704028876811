import {Circle, MapContainer, Polyline, TileLayer} from "../../_snowpack/pkg/react-leaflet.js";
import * as React from "../../_snowpack/pkg/react.js";
import {MapFitBounds} from "./MapFitBounds.js";
export const TripDetailsMap = (props) => {
  const points = props.routeParts.flatMap((routePart) => routePart);
  return /* @__PURE__ */ React.createElement(MapContainer, {
    center: {lng: props.normParams.centerLon, lat: props.normParams.centerLat},
    zoom: 15,
    style: {height: "100%"},
    attributionControl: false
  }, /* @__PURE__ */ React.createElement(TileLayer, {
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  }), /* @__PURE__ */ React.createElement(MapFitBounds, {
    normParams: props.normParams
  }), points.map(createLine), points.map(createCircle));
};
const createLine = (from, index, points) => {
  const to = points[(index + 1) % points.length];
  return /* @__PURE__ */ React.createElement(Polyline, {
    positions: [
      {lng: from.lon, lat: from.lat},
      {lng: to.lon, lat: to.lat}
    ],
    pathOptions: {color: "black"}
  });
};
const createCircle = (point) => /* @__PURE__ */ React.createElement(Circle, {
  center: {lng: point.lon, lat: point.lat},
  pathOptions: {color: "black", fillColor: "black"},
  radius: 750
});
