import * as React from "../../_snowpack/pkg/react.js";
import {Box, ImageList, ImageListItem, ImageListItemBar} from "../../_snowpack/pkg/@mui/material.js";
import {SmartImage} from "./SmartImage.js";
import {APP_SPACING} from "../theme.js";
import {calculateFullScrCols} from "../util/fullscreen_column_calculator.js";
import {emToPx} from "../util/units.js";
export const TripDetailsPhotos = (props) => {
  const columns = calculateFullScrCols();
  return /* @__PURE__ */ React.createElement(Box, {
    sx: {
      height: "100%",
      overflowY: "scroll",
      "::-webkit-scrollbar": {
        display: "none"
      },
      scrollbarWidth: "none"
    }
  }, /* @__PURE__ */ React.createElement(ImageList, {
    cols: columns,
    gap: emToPx(APP_SPACING / 2),
    sx: {marginY: 0}
  }, props.trip.photos.map((asset) => /* @__PURE__ */ React.createElement(ImageListItem, null, /* @__PURE__ */ React.createElement(SmartImage, {
    asset
  }), asset.desc && /* @__PURE__ */ React.createElement(ImageListItemBar, {
    subtitle: `${asset.desc}`,
    sx: {borderRadius: "0 0 4px 4px"}
  })))));
};
