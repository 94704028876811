import * as React from "../_snowpack/pkg/react.js";
import {CssBaseline, ThemeProvider} from "../_snowpack/pkg/@mui/material.js";
import {getTheme} from "./theme.js";
import {Root} from "./component/Root.js";
import {supportsTouch} from "./util/feature_detector.js";
import {VerticalHeightProvider} from "./util/vertical_height.js";
export const App = () => {
  supportsTouch();
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(CssBaseline, null), /* @__PURE__ */ React.createElement(VerticalHeightProvider, null, /* @__PURE__ */ React.createElement(ThemeProvider, {
    theme: getTheme
  }, /* @__PURE__ */ React.createElement(Root, null))));
};
