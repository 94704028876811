import * as React from "../../_snowpack/pkg/react.js";
import {useEffect, useState} from "../../_snowpack/pkg/react.js";
import {Box, Button, Card, CardContent, Grid, Typography, useMediaQuery} from "../../_snowpack/pkg/@mui/material.js";
import {APP_ELEVATION, APP_SPACING} from "../theme.js";
import {formatTime} from "../util/time_format.js";
import {drawMap} from "../util/draw_minimap.js";
import {TripDetails} from "./TripDetails.js";
import {FadeBars} from "./FadeBars.js";
import {SmartImage} from "./SmartImage.js";
import {DETAILS_TAB} from "../enum/trip_details_tab.js";
import {ScrollButtons} from "./ScrollButtons.js";
import {getTripPackages} from "../util/asset_resolver.js";
export const TripList = () => {
  const scrollRoot = React.useRef(null);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Box, {
    sx: {
      display: "flex",
      overflowX: "scroll",
      scrollSnapType: "x mandatory",
      scrollBehavior: "smooth",
      "::-webkit-scrollbar": {
        display: "none"
      },
      scrollbarWidth: "none"
    },
    ref: scrollRoot
  }, getTripPackages().map((trip, index, array) => /* @__PURE__ */ React.createElement(TripWidget, {
    trip,
    leftMost: index == 0,
    rightMost: index == array.length - 1
  })), /* @__PURE__ */ React.createElement("div", {
    style: {color: "white", fontSize: "1px"}
  }, "|")), /* @__PURE__ */ React.createElement(FadeBars, null), /* @__PURE__ */ React.createElement(ScrollButtons, {
    scrollRoot
  }));
};
const TripWidget = (props) => {
  const PEEK_WIDTH = "3em";
  const N = calculateNumberOfWidgets();
  const pageWidth = useMediaQuery("(max-width: 1200px)") ? "100vw" : "1200px";
  const containerPadding = useMediaQuery("(max-width: 599px)") ? 2 : 3;
  const availableWidth = `(${pageWidth} - ${containerPadding}em - ${PEEK_WIDTH})`;
  const widgetWidth = `(${availableWidth} - ${N}em + 1em) / ${N}`;
  const map = React.createRef();
  useEffect(() => {
    drawMap(props.trip, map);
  }, []);
  const [open, setOpen] = useState(DETAILS_TAB.CLOSED);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Box, {
    marginLeft: props.leftMost ? `${containerPadding / 2}em` : "0.5em",
    marginRight: props.rightMost ? `${containerPadding / 2}em` : "0.5em",
    pb: APP_SPACING,
    sx: {scrollSnapAlign: "center"}
  }, /* @__PURE__ */ React.createElement(Card, {
    elevation: APP_ELEVATION
  }, /* @__PURE__ */ React.createElement(CardContent, {
    sx: {
      width: `calc(${widgetWidth})`
    }
  }, /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    alignItems: "center",
    spacing: APP_SPACING
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 7
  }, /* @__PURE__ */ React.createElement(Typography, {
    component: "h2",
    fontWeight: "bold"
  }, props.trip.name)), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 5,
    textAlign: "right"
  }, /* @__PURE__ */ React.createElement(Typography, {
    variant: "body2"
  }, "~", formatTime(props.trip.duration))), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 6,
    sx: {cursor: "pointer"},
    onClick: () => setOpen(DETAILS_TAB.MAP)
  }, /* @__PURE__ */ React.createElement("canvas", {
    ref: map,
    style: {width: "100%"}
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 6,
    sx: {cursor: "pointer"},
    onClick: () => setOpen(DETAILS_TAB.PHOTOS)
  }, /* @__PURE__ */ React.createElement(SmartImage, {
    asset: props.trip.thumbnail
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 12,
    height: "5em",
    sx: {overflow: "hidden", cursor: "pointer"},
    onClick: () => setOpen(DETAILS_TAB.ABOUT)
  }, /* @__PURE__ */ React.createElement(Typography, {
    textOverflow: "ellipsis",
    variant: "body2"
  }, props.trip.description)), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 12
  }, /* @__PURE__ */ React.createElement(Button, {
    variant: "outlined",
    fullWidth: true,
    onClick: () => setOpen(DETAILS_TAB.MAP)
  }, "Details")))))), open != DETAILS_TAB.CLOSED && /* @__PURE__ */ React.createElement(TripDetails, {
    initialTab: open,
    setOpen,
    routeParts: props.trip.routeParts,
    normParams: props.trip.normParams,
    trip: props.trip
  }));
};
const calculateNumberOfWidgets = () => {
  const widthUnder600 = useMediaQuery("(max-width: 599px)");
  const widthUnder900 = useMediaQuery("(max-width: 899px)");
  const widthUnder1200 = useMediaQuery("(max-width: 1199px)");
  if (widthUnder600) {
    return 1;
  } else if (widthUnder900) {
    return 2;
  } else if (widthUnder1200) {
    return 3;
  } else {
    return 4;
  }
};
