let assetHash = "";
export const setHash = (hash) => {
  assetHash = hash;
};
export const getHash = () => {
  if (assetHash.length === 0) {
    throw new Error("Hash has not been set in asset resolver.");
  }
  return assetHash;
};
let assetSkeletons = null;
export const setAssetSkeletons = (skeletons) => {
  assetSkeletons = JSON.parse(skeletons);
};
export const imageSkeleton = (asset) => {
  if (assetSkeletons === null) {
    throw new Error("Image skeletons have not been set in asset resolver.");
  }
  return assetSkeletons[asset.file];
};
let tripPackages = null;
export const setTripPackages = (packages) => {
  tripPackages = JSON.parse(packages);
};
export const getTripPackages = () => {
  if (tripPackages === null) {
    throw new Error("Trip packages have not been set in asset resolver.");
  }
  return tripPackages;
};
