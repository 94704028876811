import * as React from "../../_snowpack/pkg/react.js";
import {
  Box,
  Container,
  Grid,
  Stack,
  Portal,
  useMediaQuery
} from "../../_snowpack/pkg/@mui/material.js";
import {APP_SPACING} from "../theme.js";
import {Hero} from "./Hero.js";
import {Calculator} from "./Calculator.js";
import {createCalculatorState} from "../state/calculator_state.js";
import {Bill} from "./Bill.js";
import {forceReRender} from "../util/force_re_render.js";
import {TripList} from "./TripList.js";
import {Info} from "./Info.js";
import {About} from "./About.js";
import {Logo} from "./Logo.js";
import {Book} from "./Book.js";
import {Footer} from "./Footer.js";
import {OnTheDay} from "./OnTheDay.js";
import {Intro} from "./Intro.js";
import {useContext} from "../../_snowpack/pkg/react.js";
import {VerticalHeight} from "../util/vertical_height.js";
export const Root = () => {
  forceReRender();
  const calculatorState = createCalculatorState();
  const underHeroContainer = React.useRef(null);
  const underCalculatorContainer = React.useRef(null);
  const billUnderHero = useMediaQuery("(min-width: 600px) and (max-width: 899px)");
  const verticalHeight = useContext(VerticalHeight);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Portal, {
    container: (billUnderHero ? underHeroContainer : underCalculatorContainer).current
  }, /* @__PURE__ */ React.createElement(Bill, {
    ...calculatorState
  })), /* @__PURE__ */ React.createElement(Container, {
    maxWidth: "lg",
    disableGutters: true,
    style: {minHeight: `calc(${verticalHeight}px - 6rem)`}
  }, /* @__PURE__ */ React.createElement(Logo, null), /* @__PURE__ */ React.createElement(Intro, null)), /* @__PURE__ */ React.createElement(Container, {
    maxWidth: "lg",
    disableGutters: true
  }, /* @__PURE__ */ React.createElement(TripList, null)), /* @__PURE__ */ React.createElement(Container, {
    maxWidth: "lg"
  }, /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    spacing: APP_SPACING,
    mb: APP_SPACING
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 12,
    sm: 6
  }, /* @__PURE__ */ React.createElement(Stack, {
    spacing: APP_SPACING
  }, /* @__PURE__ */ React.createElement(Hero, null), /* @__PURE__ */ React.createElement(Box, {
    ref: underHeroContainer
  }))), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 12,
    sm: 6
  }, /* @__PURE__ */ React.createElement(Stack, {
    spacing: APP_SPACING
  }, /* @__PURE__ */ React.createElement(Calculator, {
    ...calculatorState
  }), /* @__PURE__ */ React.createElement(Box, {
    ref: underCalculatorContainer
  }))))), /* @__PURE__ */ React.createElement(Container, {
    maxWidth: "lg"
  }, /* @__PURE__ */ React.createElement(OnTheDay, {
    calculatorState
  }), /* @__PURE__ */ React.createElement(Book, null), /* @__PURE__ */ React.createElement(About, null), /* @__PURE__ */ React.createElement(Info, null), /* @__PURE__ */ React.createElement(Footer, null)));
};
