import * as React from "../../_snowpack/pkg/react.js";
import {Button, Card, CardContent, Container, Grid, Typography} from "../../_snowpack/pkg/@mui/material.js";
import EmailIcon from "../../_snowpack/pkg/@mui/icons-material/Email.js";
import InstagramIcon from "../../_snowpack/pkg/@mui/icons-material/Instagram.js";
import {APP_ELEVATION, APP_SPACING} from "../theme.js";
import {INSTAGRAM_LINK, MAILTO_LINK} from "../util/links.js";
export const Book = () => {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Card, {
    elevation: APP_ELEVATION,
    sx: {marginBottom: APP_SPACING}
  }, /* @__PURE__ */ React.createElement(CardContent, null, /* @__PURE__ */ React.createElement(Typography, {
    gutterBottom: true,
    variant: "h2",
    component: "h2"
  }, "Fly with me!"), /* @__PURE__ */ React.createElement(Typography, null, "Are you excited to join me for a trip either yourself or with your friends? Drop me a message and we'll find time and the right trip for you!"), /* @__PURE__ */ React.createElement(Container, {
    maxWidth: "sm",
    sx: {marginTop: APP_SPACING, padding: 0}
  }, /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    spacing: APP_SPACING
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 6
  }, /* @__PURE__ */ React.createElement(Button, {
    variant: "contained",
    startIcon: /* @__PURE__ */ React.createElement(EmailIcon, null),
    fullWidth: true,
    onClick: () => window.open(MAILTO_LINK, "_blank")
  }, "Email me!")), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 6
  }, /* @__PURE__ */ React.createElement(Button, {
    variant: "contained",
    startIcon: /* @__PURE__ */ React.createElement(InstagramIcon, null),
    fullWidth: true,
    sx: {
      background: "linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)"
    },
    onClick: () => window.open(INSTAGRAM_LINK, "_blank")
  }, "DM me!")))))));
};
