import * as React from "../../_snowpack/pkg/react.js";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from "../../_snowpack/pkg/@mui/lab.js";
import {
  Box,
  Card,
  CardContent,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery
} from "../../_snowpack/pkg/@mui/material.js";
import {APP_ELEVATION, APP_SPACING} from "../theme.js";
import {useState} from "../../_snowpack/pkg/react.js";
import {b} from "../util/value_watcher.js";
const FLIGHT_TIMES = [
  {t: 9 * 60, n: "Morning Flight"},
  {t: 12 * 60, n: "Afternoon Flight"}
];
export const OnTheDay = (props) => {
  const [tripStart, setTripStart] = useState(FLIGHT_TIMES[0]?.t || 0);
  const sideBySide = useMediaQuery("(min-width: 750px)");
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Card, {
    elevation: APP_ELEVATION
  }, /* @__PURE__ */ React.createElement(CardContent, null, /* @__PURE__ */ React.createElement(Typography, {
    gutterBottom: true,
    variant: "h2",
    component: "h2"
  }, "On The Day"), /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    spacing: APP_SPACING
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: sideBySide ? 6 : 12
  }, /* @__PURE__ */ React.createElement(Box, {
    marginTop: APP_SPACING
  }, /* @__PURE__ */ React.createElement(Typography, {
    paddingX: APP_SPACING,
    paddingBottom: APP_SPACING
  }, "Timeline is based on the trip length selected using the slider above."), /* @__PURE__ */ React.createElement(ToggleButtonGroup, {
    exclusive: true,
    fullWidth: true,
    size: "small",
    value: tripStart,
    onChange: b(setTripStart, false)
  }, FLIGHT_TIMES.map((ft) => /* @__PURE__ */ React.createElement(ToggleButton, {
    value: ft.t
  }, ft.n))), /* @__PURE__ */ React.createElement(Typography, {
    paddingX: APP_SPACING,
    paddingTop: APP_SPACING
  }, tripStart === FLIGHT_TIMES[0]?.t ? "Flying in the morning gives a little smoother air and plenty of time to go on long trips." : "Flying in the afternoon recognizes some people cannot miss out on a night out on the day before."))), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: sideBySide ? 6 : 12
  }, /* @__PURE__ */ React.createElement(Timeline, null, calculateTripMilestones(props.calculatorState, tripStart).map((item, index, array) => /* @__PURE__ */ React.createElement(TimelineItem, null, /* @__PURE__ */ React.createElement(TimelineOppositeContent, {
    sx: {
      flexGrow: "0.33"
    }
  }, timeToString(item.t)), /* @__PURE__ */ React.createElement(TimelineSeparator, null, /* @__PURE__ */ React.createElement(TimelineDot, {
    color: "primary"
  }), index < array.length - 1 && /* @__PURE__ */ React.createElement(TimelineConnector, null)), /* @__PURE__ */ React.createElement(TimelineContent, null, item.n)))))))), /* @__PURE__ */ React.createElement(Box, {
    mb: APP_SPACING
  }));
};
const calculateTripMilestones = (calculatorState, tripStart) => {
  const preFlightItems = [
    {n: "Leave from Shoreditch", l: 45},
    {n: "Arrive at the Airport", l: 60}
  ];
  const flightItems = calculatorState.landingAway ? [
    {n: "Take-off", l: calculatorState.tripLength / 2},
    {n: "Landing Away", l: 120},
    {n: "Take-off Away", l: calculatorState.tripLength / 2}
  ] : [
    {n: "Take-off", l: calculatorState.tripLength}
  ];
  const postFlightItems = [
    {n: "Landing", l: 60},
    {n: "Leave from the Airport", l: 60},
    {n: "Back to Shoreditch", l: 0}
  ];
  const allItems = [...preFlightItems, ...flightItems, ...postFlightItems];
  const tripMilestones = [];
  let time = tripStart;
  allItems.forEach((item) => {
    tripMilestones.push({n: item.n, t: time});
    time += 5 * Math.ceil(item.l / 5);
  });
  return tripMilestones;
};
const timeToString = (time) => {
  const hours = Math.floor(time / 60);
  const mins = time - hours * 60;
  const minsPadding = mins < 10 ? "0" : "";
  return `${hours}:${minsPadding}${mins}`;
};
