import * as React from "../../_snowpack/pkg/react.js";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Modal,
  Tab,
  Tabs,
  useMediaQuery
} from "../../_snowpack/pkg/@mui/material.js";
import CloseRoundedIcon from "../../_snowpack/pkg/@mui/icons-material/CloseRounded.js";
import {b} from "../util/value_watcher.js";
import {APP_SPACING} from "../theme.js";
import {DETAILS_TAB} from "../enum/trip_details_tab.js";
import {TripDetailsMap} from "./TripDetailsMap.js";
import {TripDetailsPhotos} from "./TripDetailsPhotos.js";
import {useContext} from "../../_snowpack/pkg/react.js";
import {VerticalHeight} from "../util/vertical_height.js";
export const TripDetails = (props) => {
  const [openTab, setOpenTab] = React.useState(availableTabFallback(props.initialTab, props.trip));
  const modalMargin = useMediaQuery("(min-width: 450px)") ? "2em" : "1em";
  const verticalHeight = useContext(VerticalHeight);
  const tabContentHeight = `calc(${verticalHeight}px - 6em - 4px - 2 * ${modalMargin})`;
  return /* @__PURE__ */ React.createElement(Modal, {
    open: true
  }, /* @__PURE__ */ React.createElement(Card, {
    sx: {
      width: `calc(100vw - 2 * ${modalMargin})`,
      height: `calc(${verticalHeight}px - 2 * ${modalMargin})`,
      marginX: modalMargin,
      marginY: modalMargin
    }
  }, /* @__PURE__ */ React.createElement(CardContent, {
    sx: {height: "100%", paddingBottom: "1em"}
  }, /* @__PURE__ */ React.createElement(Box, {
    marginBottom: APP_SPACING,
    sx: {display: "flex"}
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {flex: "1", width: 0}
  }, /* @__PURE__ */ React.createElement(Tabs, {
    variant: "scrollable",
    value: openTab,
    onChange: b(setOpenTab)
  }, /* @__PURE__ */ React.createElement(Tab, {
    label: "Map",
    value: DETAILS_TAB.MAP
  }), props.trip.photos.length > 0 && /* @__PURE__ */ React.createElement(Tab, {
    label: "Photos",
    value: DETAILS_TAB.PHOTOS
  }))), /* @__PURE__ */ React.createElement(IconButton, {
    "aria-label": "close",
    onClick: () => props.setOpen(DETAILS_TAB.CLOSED),
    sx: {
      flex: "0 0 2em",
      color: (theme) => theme.palette.grey[600]
    }
  }, /* @__PURE__ */ React.createElement(CloseRoundedIcon, {
    sx: {fontSize: "1.5em"}
  }))), openTab == DETAILS_TAB.MAP && /* @__PURE__ */ React.createElement(Box, {
    height: tabContentHeight
  }, /* @__PURE__ */ React.createElement(TripDetailsMap, {
    routeParts: props.routeParts,
    normParams: props.normParams
  })), openTab == DETAILS_TAB.ABOUT && /* @__PURE__ */ React.createElement(Box, {
    height: tabContentHeight
  }, "PLACEHOLDER ABOUT"), openTab == DETAILS_TAB.PHOTOS && /* @__PURE__ */ React.createElement(Box, {
    height: tabContentHeight
  }, /* @__PURE__ */ React.createElement(TripDetailsPhotos, {
    trip: props.trip
  })))));
};
const availableTabFallback = (tab, trip) => {
  if (tab == DETAILS_TAB.PHOTOS && trip.photos.length == 0) {
    return DETAILS_TAB.MAP;
  } else if (tab == DETAILS_TAB.ABOUT) {
    return DETAILS_TAB.MAP;
  } else {
    return tab;
  }
};
