import * as React from "../../_snowpack/pkg/react.js";
import {Box, Grid, IconButton, Typography, useMediaQuery} from "../../_snowpack/pkg/@mui/material.js";
import InstagramIcon from "../../_snowpack/pkg/@mui/icons-material/Instagram.js";
import {APP_SPACING} from "../theme.js";
import {INSTAGRAM_LINK} from "../util/links.js";
export const Footer = () => {
  const isNarrow = useMediaQuery("(max-width: 599px)");
  const COLOR = "rgb(128, 128, 128)";
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Box, {
    marginX: APP_SPACING,
    marginY: APP_SPACING * 2
  }, /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    spacing: APP_SPACING * 2
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 12,
    sm: 5,
    style: {
      textAlign: isNarrow ? "center" : "left"
    }
  }, /* @__PURE__ */ React.createElement("img", {
    src: "assets/logo.svg",
    width: 90,
    height: 24,
    alt: "Fly With Me",
    style: {filter: "grayscale(100%)"}
  }), /* @__PURE__ */ React.createElement(Typography, {
    variant: "body2",
    sx: {
      color: COLOR
    }
  }, "© Petr Hoffmann. All rights reserved.")), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 12,
    sm: 2,
    sx: {
      textAlign: "center"
    }
  }), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 12,
    sm: 5,
    style: {
      textAlign: isNarrow ? "center" : "right"
    }
  }, /* @__PURE__ */ React.createElement(IconButton, {
    color: "default",
    onClick: () => window.open(INSTAGRAM_LINK, "_blank")
  }, /* @__PURE__ */ React.createElement(InstagramIcon, null))))));
};
