export const w = (setter) => {
  return (event) => {
    setter(event.target.value);
  };
};
export const b = (setter, allowEmpty = true) => {
  return (_, value) => {
    if (allowEmpty || value) {
      setter(value);
    }
  };
};
