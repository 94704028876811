import {Box, useMediaQuery} from "../../_snowpack/pkg/@mui/material.js";
import * as React from "../../_snowpack/pkg/react.js";
export const Logo = () => {
  const ASPECT_RATIO = 240 / 64;
  const width = useMediaQuery("(max-width: 899px)") ? 200 : 240;
  const height = Math.floor(width / ASPECT_RATIO);
  return /* @__PURE__ */ React.createElement(Box, {
    sx: {
      margin: "1em 0.5em",
      width: `${width}px`,
      height: `${height}px`
    }
  }, /* @__PURE__ */ React.createElement("img", {
    src: "assets/logo.svg",
    width,
    height,
    alt: "Fly With Me",
    style: {margin: "0", width: "100%", height: "100%"}
  }));
};
