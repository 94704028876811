import {createTheme} from "../_snowpack/pkg/@mui/material.js";
export const APP_SPACING = 2;
export const APP_ELEVATION = 2;
export const getTheme = createTheme({
  typography: {
    h1: {
      fontWeight: 600,
      fontSize: "3rem"
    },
    h2: {
      fontWeight: 600,
      fontSize: "1.5rem"
    },
    h3: {
      fontWeight: 600
    },
    h4: {
      fontWeight: 600
    }
  }
});
