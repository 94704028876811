import * as React from "../../_snowpack/pkg/react.js";
import {Card} from "../../_snowpack/pkg/@mui/material.js";
import {imageFallbackUrl, imageUrlSet} from "../util/image_util.js";
import {imageSkeleton} from "../util/asset_resolver.js";
export const SmartImage = (props) => {
  const [width, height] = props.asset.res.split("x");
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Card, {
    elevation: props.elevation ? props.elevation : 0,
    sx: props.sx ? props.sx : {}
  }, /* @__PURE__ */ React.createElement("img", {
    src: imageFallbackUrl(props.asset),
    width,
    height,
    alt: props.asset.desc,
    srcSet: imageUrlSet(props.asset),
    style: {
      display: "block",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundImage: `url(data:image/webp;base64,${imageSkeleton(props.asset)})`,
      width: "100%",
      height: "100%",
      objectFit: "cover"
    }
  })));
};
