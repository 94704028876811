import * as React from "../../_snowpack/pkg/react.js";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  useMediaQuery
} from "../../_snowpack/pkg/@mui/material.js";
import {APP_ELEVATION, APP_SPACING} from "../theme.js";
import {AIRPORT_TRANSPORT} from "../enum/airport_transport_enum.js";
import {roundTo} from "../util/math.js";
export const Bill = (state) => {
  const smallerText = useMediaQuery("(min-width: 600px) and (max-width: 749px)");
  const boldSx = {fontWeight: "bold"};
  const PLANE_HOURLY = 189;
  const PASSENGER_FEE = 15;
  const LANDING_FEE = 27;
  const FUEL_PER_HOUR = 36;
  const CO2_PER_TREE = 22;
  const TREE_COST = 0.83;
  const ZIPCAR_HOURLY = 9;
  const ZIPCAR_FIXED = 15;
  const ZIPCAR_DAILY_CAP = 90;
  const hours = roundTo(state.tripLength / 60, 2);
  const planeHire = Math.floor(hours * PLANE_HOURLY);
  const zipcarHours = calcZipcarHours(hours, state.landingAway);
  const zipcarCost = Math.floor(Math.min(zipcarHours * ZIPCAR_HOURLY, ZIPCAR_DAILY_CAP) + ZIPCAR_FIXED);
  const transport = state.airportTransport === AIRPORT_TRANSPORT.ZIPCAR ? zipcarCost : 0;
  const peopleOnboard = state.passengers + 1;
  const passengerFees = state.passengers * PASSENGER_FEE;
  const landingFees = state.landingAway ? LANDING_FEE : 0;
  const fuelBurnt = hours * FUEL_PER_HOUR;
  const fuelWeightBurnt = fuelBurnt * 0.72;
  const tonsCo2Emitted = roundTo(fuelWeightBurnt * 3.1 * 1e-3, 2);
  const costPerTonCo2Offset = Math.round(1e3 / CO2_PER_TREE * TREE_COST);
  const carbonOffset = Math.round(tonsCo2Emitted * costPerTonCo2Offset);
  const total = planeHire + transport + passengerFees + landingFees + carbonOffset;
  const pp = Math.floor(total / peopleOnboard);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Card, {
    elevation: APP_ELEVATION
  }, /* @__PURE__ */ React.createElement(CardContent, null, /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    sx: {fontSize: smallerText ? "0.75em" : "1em"}
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 5
  }, "Plane Hire"), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 5
  }, hours.toFixed(2), "h x £", PLANE_HOURLY, "/h"), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 2,
    sx: {textAlign: "right"}
  }, "£", planeHire), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 5
  }, "Car Hire"), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 5
  }, state.airportTransport === AIRPORT_TRANSPORT.ZIPCAR && `${zipcarHours.toFixed(1)}h`), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 2,
    sx: {textAlign: "right"}
  }, "£", transport), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 5
  }, "Headsets Hire"), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 5
  }, state.passengers, " x £", PASSENGER_FEE), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 2,
    sx: {textAlign: "right"}
  }, "£", passengerFees), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 5
  }, "Landing Fees"), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 5
  }), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 2,
    sx: {textAlign: "right"}
  }, "£", landingFees), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 5
  }, "Carbon Offsetting"), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 5
  }, tonsCo2Emitted.toFixed(2), "t CO", /* @__PURE__ */ React.createElement("sub", null, "2"), " x £", costPerTonCo2Offset), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 2,
    sx: {textAlign: "right"}
  }, "£", carbonOffset), /* @__PURE__ */ React.createElement(Grid, {
    xs: 12,
    mt: APP_SPACING / 2,
    mb: APP_SPACING / 2
  }, /* @__PURE__ */ React.createElement(Divider, null)), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 5
  }, "Total"), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 5
  }), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 2,
    sx: {textAlign: "right"}
  }, "£", total), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 5,
    sx: boldSx
  }, "Per Person"), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 5,
    sx: boldSx
  }, "£", total, " / ", peopleOnboard), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 2,
    sx: {textAlign: "right", ...boldSx}
  }, "£", pp)))));
};
const calcZipcarHours = (hours, landingAway) => {
  return 1 + 1 + Math.ceil(hours * 2) / 2 + (landingAway ? 2.5 : 0) + 1 + 0.5;
};
