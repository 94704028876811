import * as React from "../../_snowpack/pkg/react.js";
import {SmartImage} from "./SmartImage.js";
import {HERO} from "../../assets_raw/RAW_ASSETS.js";
import {APP_ELEVATION} from "../theme.js";
export const Hero = () => {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(SmartImage, {
    asset: HERO,
    elevation: APP_ELEVATION
  }));
};
