import {useEffect, useState} from "../../_snowpack/pkg/react.js";
export const calculateFullScrCols = () => {
  const [columns, setColumns] = useState(determine);
  const update = () => {
    setColumns(determine);
  };
  useEffect(() => {
    window.addEventListener("resize", update);
    return () => {
      window.removeEventListener("resize", update);
    };
  }, []);
  return columns;
};
const determine = () => {
  return Math.min(3, Math.ceil(window.innerWidth / window.innerHeight));
};
