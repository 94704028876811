import * as React from "../../_snowpack/pkg/react.js";
import {Box, Card, CardContent, Typography} from "../../_snowpack/pkg/@mui/material.js";
import {APP_ELEVATION, APP_SPACING} from "../theme.js";
import {SmartImage} from "./SmartImage.js";
import {ME} from "../../assets_raw/RAW_ASSETS.js";
export const About = () => {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Card, {
    elevation: APP_ELEVATION
  }, /* @__PURE__ */ React.createElement(CardContent, null, /* @__PURE__ */ React.createElement(Typography, {
    gutterBottom: true,
    variant: "h2",
    component: "h2"
  }, "About Me"), /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Box, {
    sx: {
      width: "6.5em",
      float: "left",
      margin: "0 1em 1em 0"
    }
  }, /* @__PURE__ */ React.createElement(SmartImage, {
    asset: ME
  })), /* @__PURE__ */ React.createElement(Typography, {
    gutterBottom: true
  }, "My name is Petr Hoffmann and I absolutely love flying! I did my training in summer 2021 after finishing uni and before starting a software engineering job in London. I've been taking family and friends in the sky ever since I received my license, and I'll be delighted for you to join me too!")))), /* @__PURE__ */ React.createElement(Box, {
    mb: APP_SPACING
  }));
};
