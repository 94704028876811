import * as React from "../../_snowpack/pkg/react.js";
import {useEffect, useState} from "../../_snowpack/pkg/react.js";
import {Box, IconButton} from "../../_snowpack/pkg/@mui/material.js";
import ArrowCircleLeftIcon from "../../_snowpack/pkg/@mui/icons-material/ArrowCircleLeft.js";
import ArrowCircleRightIcon from "../../_snowpack/pkg/@mui/icons-material/ArrowCircleRight.js";
import {supportsTouch} from "../util/feature_detector.js";
export const ScrollButtons = (props) => {
  const r = props.scrollRoot.current;
  const touch = supportsTouch();
  if ((touch == null || !touch) && r != null) {
    return /* @__PURE__ */ React.createElement(ButtonImplementation, {
      r
    });
  } else {
    return null;
  }
};
const ButtonImplementation = (props) => {
  const [displayLeft, setDisplayLeft] = useState(true);
  const [displayRight, setDisplayRight] = useState(true);
  useEffect(() => {
    const listener = () => {
      if (props.r.scrollLeft === 0) {
        setDisplayLeft(false);
      } else {
        setDisplayLeft(true);
      }
      if (props.r.scrollWidth - props.r.offsetWidth - props.r.scrollLeft < 5) {
        setDisplayRight(false);
      } else {
        setDisplayRight(true);
      }
    };
    if (props.r != null) {
      props.r.addEventListener("scroll", listener);
      listener();
      return () => {
        props.r.removeEventListener("scroll", listener);
      };
    } else {
      return () => {
      };
    }
  }, [props.r]);
  const style = {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "rgb(224,224,224)"
    }
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, displayLeft && /* @__PURE__ */ React.createElement(Box, {
    width: 0,
    height: 0,
    sx: {
      position: "relative",
      bottom: "14em",
      left: "2em"
    }
  }, /* @__PURE__ */ React.createElement(IconButton, {
    color: "primary",
    sx: style,
    onClick: () => scroll(props.r, false)
  }, /* @__PURE__ */ React.createElement(ArrowCircleLeftIcon, {
    sx: {fontSize: "2em"}
  }))), displayRight && /* @__PURE__ */ React.createElement(Box, {
    width: 0,
    height: 0,
    sx: {
      position: "relative",
      float: "right",
      bottom: "14em",
      right: "6em"
    }
  }, /* @__PURE__ */ React.createElement(IconButton, {
    color: "info",
    sx: style,
    onClick: () => scroll(props.r, true)
  }, /* @__PURE__ */ React.createElement(ArrowCircleRightIcon, {
    sx: {fontSize: "2em"}
  }))));
};
const scroll = (root, right) => {
  const shift = root.children[0].scrollWidth;
  if (right) {
    root.scrollBy(shift, 0);
  } else {
    root.scrollBy(-shift, 0);
  }
};
