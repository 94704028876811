import {useState} from "../../_snowpack/pkg/react.js";
import {AIRPORT_TRANSPORT} from "../enum/airport_transport_enum.js";
export const createCalculatorState = () => {
  const [passengers, setPassengers] = useState(2);
  const [tripLength, setTripLength] = useState(1.5 * 60);
  const [preferredLandingAway, setPreferredLandingAway] = useState(false);
  const [airportTransport, setAirportTransport] = useState(AIRPORT_TRANSPORT.ZIPCAR);
  const forcedLandingAway = tripLength > 2 * 60;
  const landingAway = preferredLandingAway || forcedLandingAway;
  return {
    passengers,
    setPassengers,
    tripLength,
    setTripLength,
    preferredLandingAway,
    setPreferredLandingAway,
    forcedLandingAway,
    landingAway,
    airportTransport,
    setAirportTransport
  };
};
