import * as React from "../../_snowpack/pkg/react.js";
import {Box, Typography, useMediaQuery} from "../../_snowpack/pkg/@mui/material.js";
import {useContext} from "../../_snowpack/pkg/react.js";
import {VerticalHeight} from "../util/vertical_height.js";
export const Intro = () => {
  const widthCategory = getWidthCategory();
  const breakLine = useMediaQuery("(max-width: 449px)");
  const titleFontSize = (widthCategory + 1) * 0.75;
  const verticalHeight = useContext(VerticalHeight);
  const spacerHeight = `calc(${verticalHeight / 2}px - ${(breakLine ? 3 + titleFontSize * 2 : 2 + titleFontSize) / 2}rem - 2rem - 64px)`;
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Box, {
    marginTop: spacerHeight
  }, /* @__PURE__ */ React.createElement(Typography, {
    textAlign: "center",
    variant: "h1",
    component: "h1",
    gutterBottom: true,
    style: {fontSize: `${titleFontSize}rem`}
  }, "THE ", /* @__PURE__ */ React.createElement("span", {
    style: {fontWeight: "400"}
  }, "private pilot to go ", breakLine && /* @__PURE__ */ React.createElement("br", null), " flying with")), /* @__PURE__ */ React.createElement(Typography, {
    textAlign: "center",
    gutterBottom: true,
    variant: "body1"
  }, "Best views. Friendly approach. ", breakLine && /* @__PURE__ */ React.createElement("br", null), " Sightseeing cost-sharing flights."), /* @__PURE__ */ React.createElement(Typography, {
    textAlign: "center",
    gutterBottom: true,
    variant: "body2"
  }, "In London (within M25).")));
};
const getWidthCategory = () => {
  let category = 1;
  if (useMediaQuery("(min-width: 1000px)")) {
    category++;
  }
  if (useMediaQuery("(min-width: 600px)")) {
    category++;
  }
  return category;
};
