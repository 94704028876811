export const RAW_ASSETS = [];
const a = (asset) => {
  if (!RAW_ASSETS.includes(asset)) {
    RAW_ASSETS.push(asset);
  }
  return asset;
};
export const HERO = a({
  file: "hero.png",
  res: "568x426",
  desc: "Petr Hoffmann landing a Piper Warrior at Stapleford Airfield"
});
a({file: "hero.png", res: "1200x630", exactResOnly: true});
export const ME = a({
  file: "me.png",
  res: "104x104",
  desc: "Petr Hoffmann"
});
export const FAVICON = a({file: "me.png", res: "128x128"});
const THUMBNAIL_RES = "128x128";
export const THUMBNAIL_BRIGHTON = a({
  file: "trips/brighton.jpg",
  res: THUMBNAIL_RES,
  desc: "Trip to brighton"
});
export const THUMBNAIL_DOVER_CLIFFS = a({
  file: "trips/dover_cliffs.jpg",
  res: THUMBNAIL_RES,
  desc: "Trip to Dover Cliffs"
});
export const THUMBNAIL_IPSWICH = a({
  file: "photos/felixstowe.jpg",
  res: THUMBNAIL_RES,
  desc: "Trip to Ipswich"
});
export const THUMBNAIL_LONDON_CITY = a({
  file: "trips/london_city.jpg",
  res: THUMBNAIL_RES,
  desc: "Trip to London City"
});
export const THUMBNAIL_ISLE_OF_WIGHT = a({
  file: "photos/the_needles.jpg",
  res: THUMBNAIL_RES,
  desc: "Trip to Isle of Wight"
});
export const THUMBNAIL_PLACEHOLDER = a({
  file: "trips/placeholder.jpg",
  res: THUMBNAIL_RES,
  desc: "Placeholder image taken near Stapleford"
});
const MAIN_RES = "600x450";
export const MAIN_BRIGHTON = a({
  file: "trips/brighton.jpg",
  res: MAIN_RES,
  desc: "Brighton Beachside"
});
export const MAIN_LONDON_CITY = a({
  file: "trips/london_city.jpg",
  res: MAIN_RES,
  desc: "Canary Wharf and central London"
});
export const BATTLE_OF_BRITAIN_MEMORIAL = a({
  file: "photos/battle_of_britain_memorial.jpg",
  res: MAIN_RES,
  desc: "Battle of Britain Memorial"
});
export const BEACHY_HEAD_LIGHTHOUSE = a({
  file: "photos/beachy_head_lighthouse.jpg",
  res: MAIN_RES,
  desc: "Beachy Head Lighthouse"
});
export const BRANDS_HATCH_CIRCUIT = a({
  file: "photos/brands_hatch_circuit.jpg",
  res: MAIN_RES,
  desc: "Brands Hatch Circuit"
});
export const BRIGHTON_BEACHSIDE = a({
  file: "photos/brighton_beachside.jpg",
  res: MAIN_RES,
  desc: "Brighton Beachside"
});
export const BRIGHTON_I360 = a({
  file: "photos/brighton_i360.jpg",
  res: MAIN_RES,
  desc: "Brighton i360"
});
export const BRIGHTON_MARINA_1 = a({
  file: "photos/brighton_marina_1.jpg",
  res: MAIN_RES,
  desc: "Brighton Marina"
});
export const BRIGHTON_PIER = a({
  file: "photos/brighton_pier.jpg",
  res: MAIN_RES,
  desc: "Brighton Pier"
});
export const CANARY_WHARF_OVERWING = a({
  file: "photos/canary_wharf_overwing.jpg",
  res: MAIN_RES,
  desc: "Canary Wharf"
});
export const CANARY_WHARF_AND_CITY_OF_LONDON = a({
  file: "photos/canary_wharf_and_city_of_london.jpg",
  res: MAIN_RES,
  desc: "Canary Wharf and City of London"
});
export const CANTERBURY_CATHEDRAL = a({
  file: "photos/canterbury_cathedral.jpg",
  res: MAIN_RES,
  desc: "Canterbury Cathedral"
});
export const COLCHESTER_QUARRY = a({
  file: "photos/colchester_quarry.jpg",
  res: MAIN_RES,
  desc: "Colchester Sand and Gravel Quarry"
});
export const CUCKMERE_HEAVEN = a({
  file: "photos/cuckmere_heaven.jpg",
  res: MAIN_RES,
  desc: "Cuckmere Heaven"
});
export const DEAL_CASTLE = a({
  file: "photos/deal_castle.jpg",
  res: MAIN_RES,
  desc: "Deal Castle"
});
export const DOVER_CASTLE = a({
  file: "photos/dover_castle.jpg",
  res: MAIN_RES,
  desc: "Dover Castle"
});
export const DOVER_CLIFFS = a({
  file: "photos/dover_cliffs.jpg",
  res: MAIN_RES,
  desc: "Dover Cliffs"
});
export const DUNSFOLD = a({
  file: "photos/dunsfold.jpg",
  res: MAIN_RES,
  desc: "Dunsfold"
});
export const EPSON_DOWNS_RACECOURSE = a({
  file: "photos/epson_downs_racecourse.jpg",
  res: MAIN_RES,
  desc: "Epson Downs Racecourse"
});
export const FARMLAND = a({
  file: "photos/farmland.jpg",
  res: MAIN_RES,
  desc: "Farmland"
});
export const FELIXSTOWE = a({
  file: "photos/felixstowe.jpg",
  res: MAIN_RES,
  desc: "Port of Felixstowe"
});
export const FIRLE_PLACE = a({
  file: "photos/firle_place.jpg",
  res: MAIN_RES,
  desc: "Firle Place"
});
export const FOREST_1 = a({
  file: "photos/forest_1.jpg",
  res: MAIN_RES,
  desc: "Forest"
});
export const HM_NAVAL_BASE = a({
  file: "photos/hm_naval_base.jpg",
  res: MAIN_RES,
  desc: "HM Naval Base"
});
export const HORSTED = a({
  file: "photos/horsted.jpg",
  res: MAIN_RES,
  desc: "Horsted Place"
});
export const KNOLE_HOUSE = a({
  file: "photos/knole_house.jpg",
  res: MAIN_RES,
  desc: "Knole House"
});
export const LEE_ON_SOLENT_BEACH_1 = a({
  file: "photos/lee_on_solent_beach_1.jpg",
  res: MAIN_RES,
  desc: "Lee on Solent Beach"
});
export const LEE_ON_SOLENT_BEACH_2 = a({
  file: "photos/lee_on_solent_beach_2.jpg",
  res: MAIN_RES,
  desc: "Lee on Solent Beach"
});
export const LITLINGTON_WHITE_HORSE = a({
  file: "photos/litlington_white_horse.jpg",
  res: MAIN_RES,
  desc: "Litlington White Horse"
});
export const LONDON_STADIUM = a({
  file: "photos/london_stadium.jpg",
  res: MAIN_RES,
  desc: "London Stadium"
});
export const NEWTOWN_BAY = a({
  file: "photos/newtown_bay.jpg",
  res: MAIN_RES,
  desc: "Newtown Bay"
});
export const O2_ARENA = a({
  file: "photos/o2_arena.jpg",
  res: MAIN_RES,
  desc: "O2 Arena"
});
export const PENSHURST_PLACE = a({
  file: "photos/penshurst_place.jpg",
  res: MAIN_RES,
  desc: "Penshurst Place"
});
export const PORTSMOUTH = a({
  file: "photos/portsmouth.jpg",
  res: MAIN_RES,
  desc: "Portsmouth"
});
export const QEII = a({
  file: "photos/QEII_1.jpg",
  res: MAIN_RES,
  desc: "QEII"
});
export const RIVER_MEDWAY = a({
  file: "photos/river_medway.jpg",
  res: MAIN_RES,
  desc: "River Medway"
});
export const ROYAL_HOSPITAL_SCHOOL = a({
  file: "photos/royal_hospital_school.jpg",
  res: MAIN_RES,
  desc: "The Royal Hospital School"
});
export const RYE_HARBOUR = a({
  file: "photos/rye_harbour.jpg",
  res: MAIN_RES,
  desc: "Rye Harbour"
});
export const SELSEY = a({
  file: "photos/selsey.jpg",
  res: MAIN_RES,
  desc: "Selsey"
});
export const SEVEN_SISTERS_1 = a({
  file: "photos/seven_sisters_1.jpg",
  res: MAIN_RES,
  desc: "Seven Sisters"
});
export const SEVEN_SISTERS_2 = a({
  file: "photos/seven_sisters_2.jpg",
  res: MAIN_RES,
  desc: "Seven Sisters"
});
export const ST_MARYS_ISLAND = a({
  file: "photos/st_marys_island.jpg",
  res: MAIN_RES,
  desc: `St Mary's Island`
});
export const STRATFORD = a({
  file: "photos/stratford.jpg",
  res: MAIN_RES,
  desc: "Stratford"
});
export const THE_NEEDLES = a({
  file: "photos/the_needles.jpg",
  res: MAIN_RES,
  desc: "The Needles"
});
export const TILBURY_DOCKS = a({
  file: "photos/tilbury_docks.jpg",
  res: MAIN_RES,
  desc: "Tilbury Docks"
});
export const TILBURY_FORT = a({
  file: "photos/tilbury_fort.jpg",
  res: MAIN_RES,
  desc: "Tilbury Fort"
});
export const TOTLAND_BAY = a({
  file: "photos/totland_bay.jpg",
  res: MAIN_RES,
  desc: "Totland Bay"
});
export const WOODROLFE_CREEK = a({
  file: "photos/woodrolfe_creek.jpg",
  res: MAIN_RES,
  desc: "Woodrolfe Creek"
});
