import {drawLine, drawPoint} from "./canvas_helper.js";
import {pointsWithin} from "./math.js";
export const RESOLUTION = 512;
export const LINE_WIDTH = 16;
export const MINIMAP_MARGIN = 0.1;
export const drawMap = (trip, map) => {
  const canvas = map.current;
  canvas.width = RESOLUTION;
  canvas.height = RESOLUTION;
  const ctx = canvas.getContext("2d");
  drawCoastOutline(trip.mapOutline, ctx);
  drawRoute(trip.routeParts, ctx);
};
export const drawCoastOutline = (islandShapes, ctx) => {
  ctx.lineWidth = LINE_WIDTH / 2;
  ctx.strokeStyle = "rgb(192,192,192)";
  ctx.lineCap = "round";
  islandShapes.forEach((points) => {
    let previous = points[0];
    points.forEach((point) => {
      let [txp, typ, tx, ty] = [previous.x, previous.y, point.x, point.y].map(t);
      if (!pointsWithin(txp, typ, tx, ty, RESOLUTION / 8)) {
        txp = tx;
        typ = ty;
      }
      drawLine(ctx, txp, typ, tx, ty);
      previous = point;
    });
  });
};
export const drawRoute = (routeParts, ctx) => {
  ctx.lineWidth = LINE_WIDTH;
  ctx.lineCap = "round";
  ctx.strokeStyle = "rgba(162, 199, 240, 0.8)";
  ctx.fillStyle = "rgba(25, 118, 210, 0.8)";
  const points = routeParts.flatMap((routePart) => routePart);
  let previous = points[0];
  points.forEach((point) => {
    drawLine(ctx, t(previous.x), t(previous.y), t(point.x), t(point.y));
    previous = point;
  });
  points.forEach((point) => {
    drawPoint(ctx, t(point.x), t(point.y), LINE_WIDTH * 1.5);
  });
  ctx.fillStyle = "rgb(0, 0, 0)";
  routeParts.forEach((routePart) => {
    const point = routePart[0];
    drawPoint(ctx, t(point.x), t(point.y), LINE_WIDTH * 2);
  });
};
const t = (n) => (n * (1 - 2 * MINIMAP_MARGIN) + MINIMAP_MARGIN) * RESOLUTION;
