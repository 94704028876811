import {supportsWebp} from "./feature_detector.js";
export const altSizes = (res) => {
  const [width, height] = res.split("x").map((x) => parseInt(x));
  return [1, 0.5, 0.75, 1.5, 2].map((multiplier) => `${Math.ceil(width * multiplier)}x${Math.ceil(height * multiplier)}`);
};
const SKELETON_HEIGHT = 32;
export const skeletonSize = (res) => {
  const [width, height] = res.split("x").map((x) => parseInt(x));
  return `${Math.ceil(SKELETON_HEIGHT / height * width)}x${SKELETON_HEIGHT}`;
};
export const imageFallbackUrl = (asset) => {
  const path = asset.file.split(".").slice(0, -1).join(".") + ".jpg";
  return `assets/${asset.res}/${path}`;
};
export const imageUrlSet = (asset) => {
  const extension = supportsWebp() ? ".webp" : ".jpg";
  const path = asset.file.split(".").slice(0, -1).join(".") + extension;
  return altSizes(asset.res).map((res) => {
    const [width, _] = res.split("x");
    return `assets/${res}/${path} ${width}w`;
  }).join(", ");
};
