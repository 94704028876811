import {Box, useMediaQuery} from "../../_snowpack/pkg/@mui/material.js";
import * as React from "../../_snowpack/pkg/react.js";
export const FadeBars = () => {
  const BAR_HEIGHT = "100vh";
  const barWidth = useMediaQuery("(max-width: 599px)") ? "1em" : "1.5em";
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Box, {
    width: 0,
    height: 0,
    sx: {position: "relative"}
  }, /* @__PURE__ */ React.createElement(Box, {
    width: barWidth,
    height: `calc(${BAR_HEIGHT} + 1em)`,
    sx: {
      position: "absolute",
      top: `-${BAR_HEIGHT}`,
      background: gradient("90deg")
    }
  })), /* @__PURE__ */ React.createElement(Box, {
    width: 0,
    height: 0,
    sx: {position: "relative", float: "right"}
  }, /* @__PURE__ */ React.createElement(Box, {
    width: barWidth,
    height: `calc(${BAR_HEIGHT} + 1em)`,
    sx: {
      position: "absolute",
      top: `-${BAR_HEIGHT}`,
      left: `-${barWidth}`,
      background: gradient("270deg")
    }
  })));
};
const gradient = (direction) => {
  return `linear-gradient(${direction}, white, rgba(255, 255, 255, 0.75) 50%, rgba(255, 255, 255, 0))`;
};
