import * as React from "../../_snowpack/pkg/react.js";
import {useEffect, useState} from "../../_snowpack/pkg/react.js";
const defaultValue = window.innerHeight;
export const VerticalHeight = React.createContext(defaultValue);
export const VerticalHeightProvider = (props) => /* @__PURE__ */ React.createElement(VerticalHeight.Provider, {
  value: observeVerticalHeight()
}, props.children);
const observeVerticalHeight = () => {
  const [verticalHeight, setVerticalHeight] = useState(defaultValue);
  const updateVerticalHeight = () => {
    setVerticalHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateVerticalHeight);
    return () => {
      window.removeEventListener("resize", updateVerticalHeight);
    };
  }, []);
  return verticalHeight;
};
