import * as React from "../../_snowpack/pkg/react.js";
import {Box, Card, CardContent, Typography} from "../../_snowpack/pkg/@mui/material.js";
import {APP_ELEVATION, APP_SPACING} from "../theme.js";
import {getHash} from "../util/asset_resolver.js";
export const Info = () => {
  const caaLink = "https://www.caa.co.uk/general-aviation/aircraft-ownership-and-maintenance/cost-sharing-flights/";
  const formLink = `content/${getHash()}/passenger_declaration_and_consent.pdf`;
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Card, {
    elevation: APP_ELEVATION
  }, /* @__PURE__ */ React.createElement(CardContent, null, /* @__PURE__ */ React.createElement(Typography, {
    gutterBottom: true,
    variant: "h2",
    component: "h2"
  }, "What Are Cost-sharing Flights?"), /* @__PURE__ */ React.createElement(Typography, {
    gutterBottom: true
  }, "In a nutshell, these are flights where a private pilot (not a commercial pilot) takes passengers with them on a leisure trip for which they split the expenses. This is different to commercially operated flights which are subject to much stricter operational and safety standards. Please read all about the differences at the Civil Aviation Authority's ", /* @__PURE__ */ React.createElement("a", {
    href: caaLink,
    target: "_blank"
  }, "Cost Sharing Flights"), " information page."), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement(Typography, null, "I will make you sign a ", /* @__PURE__ */ React.createElement("a", {
    href: formLink,
    target: "_blank"
  }, "consent form"), " before flying where you will acknowledge the information above, so please read it ahead 🙂"))), /* @__PURE__ */ React.createElement(Box, {
    mb: APP_SPACING
  }));
};
