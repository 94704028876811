import * as React from "../../_snowpack/pkg/react.js";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Slider,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery
} from "../../_snowpack/pkg/@mui/material.js";
import InfoOutlinedIcon from "../../_snowpack/pkg/@mui/icons-material/InfoOutlined.js";
import WarningAmberOutlinedIcon from "../../_snowpack/pkg/@mui/icons-material/WarningAmberOutlined.js";
import {b, w} from "../util/value_watcher.js";
import {APP_ELEVATION, APP_SPACING} from "../theme.js";
import {formatTime} from "../util/time_format.js";
export const Calculator = (state) => {
  const twoColumns = useMediaQuery("(min-width: 450px) and (max-width: 599px), (min-width: 900px)");
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Card, {
    elevation: APP_ELEVATION,
    sx: {overflow: "visible"}
  }, /* @__PURE__ */ React.createElement(CardContent, null, /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    spacing: APP_SPACING
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: twoColumns ? 6 : 12
  }, /* @__PURE__ */ React.createElement(Typography, {
    gutterBottom: true,
    id: "trip-length-label"
  }, "Trip Length: ", formatTime(state.tripLength)), /* @__PURE__ */ React.createElement(Slider, {
    "aria-labelledby": "trip-length-label",
    min: 30,
    max: 3.5 * 60,
    step: 5,
    value: state.tripLength,
    onChange: w(state.setTripLength),
    marks: true,
    valueLabelDisplay: "auto",
    valueLabelFormat: formatTime
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: twoColumns ? 6 : 12
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {display: "flex"}
  }, /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Typography, {
    gutterBottom: true,
    id: "passengers-label"
  }, "Passengers: ", state.passengers)), state.passengers >= 3 && /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Tooltip, {
    title: `Total weight of passengers must not exceed 190kg including clothes and any personal items.`,
    placement: "top",
    arrow: true,
    enterTouchDelay: 0,
    leaveTouchDelay: 7500
  }, /* @__PURE__ */ React.createElement(IconButton, {
    "aria-label": "explanation",
    sx: {
      height: "1em",
      position: "relative",
      color: (theme) => theme.palette.grey[600]
    }
  }, /* @__PURE__ */ React.createElement(WarningAmberOutlinedIcon, null))))), /* @__PURE__ */ React.createElement(ToggleButtonGroup, {
    value: state.passengers,
    exclusive: true,
    onChange: b(state.setPassengers, false),
    fullWidth: true,
    size: "small"
  }, /* @__PURE__ */ React.createElement(ToggleButton, {
    value: 1
  }, "Just Me"), /* @__PURE__ */ React.createElement(ToggleButton, {
    value: 2
  }, "2"), /* @__PURE__ */ React.createElement(ToggleButton, {
    value: 3
  }, "3"))), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: twoColumns ? 6 : 12
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {display: "flex"}
  }, /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Typography, {
    gutterBottom: true,
    id: "landing-away-label"
  }, "Landing Away: ", state.landingAway ? "Yes" : "No")), /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Tooltip, {
    title: `Whether we're going to land somewhere along the route before returning to where we left from. Necessary for long trips.`,
    placement: "top",
    arrow: true,
    enterTouchDelay: 0,
    leaveTouchDelay: 7500
  }, /* @__PURE__ */ React.createElement(IconButton, {
    "aria-label": "explanation",
    sx: {
      position: "relative",
      bottom: "0.33em",
      color: (theme) => theme.palette.grey[600]
    }
  }, /* @__PURE__ */ React.createElement(InfoOutlinedIcon, null))))), /* @__PURE__ */ React.createElement(Grid, {
    container: true
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 4
  }, /* @__PURE__ */ React.createElement(Switch, {
    "aria-labelledby": "landing-away-label",
    checked: state.landingAway,
    onChange: b(state.setPreferredLandingAway),
    disabled: state.forcedLandingAway
  })), state.landingAway && /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 8
  }, /* @__PURE__ */ React.createElement(Typography, {
    fontSize: "1.5em"
  }, "⛽🍔☕🚻"))))))));
};
