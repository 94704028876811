import {useEffect, useState} from "../../_snowpack/pkg/react.js";
let webpCache = null;
export const supportsWebp = () => {
  if (webpCache == null) {
    const element = document.createElement("canvas");
    webpCache = element.toDataURL("image/webp").indexOf("data:image/webp") == 0;
  }
  return webpCache;
};
let touchSupportDetermined = false;
export const supportsTouch = () => {
  const [supports, setSupports] = useState(null);
  useEffect(() => {
    const touchListener = () => {
      if (!touchSupportDetermined) {
        touchSupportDetermined = true;
        setSupports(true);
      }
    };
    const scrollListener = () => {
      if (!touchSupportDetermined) {
        touchSupportDetermined = true;
        setSupports(false);
      }
    };
    document.addEventListener("touchstart", touchListener);
    document.addEventListener("scroll", scrollListener);
    return () => {
      document.removeEventListener("touchstart", touchListener);
      document.removeEventListener("scroll", scrollListener);
    };
  }, []);
  return supports;
};
